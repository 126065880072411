@import 'src/common/_globals.scss';

.container {
  position: fixed;
  top: $nav-height-pc + $nav-margin * 2;
  right: 2rem;
  padding: 0.75rem;
  z-index: 15;
  border-radius: 1.5rem;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: opacity ease-in-out 0.2s, transform ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.visible {
    pointer-events: initial;
    opacity: 1;
  }

  &.hidden {
    pointer-events: none;
    transform: translateY(0.5rem);
    opacity: 0;
  }

  & > * {
    margin-bottom: 0.75rem !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .menuDivider {
    height: 0;
    width: 75%;
    border-bottom: 1px solid lightgray;
    margin-top: 0.2rem;
    margin-bottom: 0.7rem !important;
  }

  .menuButton {
    background-color: $color-light-gray !important;
    text-transform: none;
    font-size: 1rem;
    border-radius: 1.25rem;
    height: 2.5rem;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    padding-left: 1.5rem;
    padding-right: 1rem;

    &:active {
      background-color: $color-light-gray !important;
    }
  }
}

@include mobile {
  .container {
    border-radius: 2rem;
    top: $nav-height-mobile + 1rem;
    right: 0.75rem;

    .menuButton {
      height: 3rem;
      border-radius: 1.5rem;
    }
  }
}

@include largeScreen {
  .container {
    right: calc(50vw - (#{$breakpoint-large-screen - 30px} / 2) + 1rem);
  }
}
