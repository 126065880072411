@import 'src/common/_globals.scss';

.introduction {
  position: relative;
  background-position: right;
  background-size: cover;
  width: 100%;
  color: white;
  overflow: hidden;

  .inner {
    padding-top: $nav-height-pc + 5rem;
    padding-bottom: 13rem;
    box-sizing: border-box;
    background: radial-gradient(
      circle at -33% 100%,
      $color-primary-darker-transparent,
      $color-primary-transparent
    );
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .sectionBorder {
      position: absolute;
      width: 100%;
      height: 5rem;
      background: white;
      border-radius: 5rem;
      bottom: -2.5rem;
    }

    .content {
      width: 55%;
      max-width: $content-max-width;

      h1 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1.1rem;
        text-align: justify;
        line-height: 1.4;
      }

      .button {
        border: 1px solid white;
        color: white;
        font-size: 1.1rem;
        text-transform: none;
        border-radius: 2rem;
        margin-top: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}

footer {
  background-color: $color-primary-footer;
  margin: 0;
  overflow: hidden;
  position: relative;
  left: 0;
  right: 0;
  padding-top: 5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 4rem;
  color: white;

  .sectionBorder {
    position: absolute;
    left: 0;
    right: 0;
    height: 5rem;
    background: white;
    border-radius: 5rem;
    top: -2.5rem;
    filter: drop-shadow(0 0.7rem 0 #00000010);
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > a {
        font-weight: 700;
        color: white;
        text-decoration: none;
        transition: opacity 0.1s ease-in-out;
        margin-right: 1rem;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.mainContent {
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;

  .downloadButton {
    display: none;
  }

  .invalid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    span {
      color: gray;
      margin-top: 0.5rem;
    }
    .icon {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  .pdfDisplay {
    width: 50rem;
    max-width: $content-max-width;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 2rem;
    border-radius: 0.5rem;
    border: 2px solid transparent;
    transition: border-color 200ms ease-in-out;
    &.loaded {
      border: 2px solid $color-light-gray;
    }
  }
}

@include mobile {
  .introduction {
    .inner {
      padding-top: $nav-height-mobile + 2rem;
      padding-bottom: 8rem;

      .sectionBorder {
        height: 2rem;
        border-radius: 2rem;
        bottom: -1rem;
      }

      .content {
        width: 90%;

        h1 {
          font-size: 1.7rem;
          text-align: center;
        }

        p {
          font-size: 1rem;
          line-height: 1.3;
        }
      }
    }
  }

  .mainContent {
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;

    .downloadButton {
      display: flex;
      border-radius: 0.5rem;
      text-transform: none;
      font-size: 1rem;
    }

    .pdfDisplay {
      display: none;
    }
  }

  footer {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;

    .sectionBorder {
      height: 2rem;
      border-radius: 2rem;
      top: -1rem;
    }

    .content {
      flex-direction: column;
      align-items: flex-start;

      .copyright {
        font-size: 0.9rem;
      }

      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1rem;

        & > a {
          font-weight: 700;
          color: white;
          text-decoration: none;
          transition: opacity 0.1s ease-in-out;
          margin-bottom: 0.66rem;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
