@import 'src/common/_globals.scss';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  min-width: fit-content;
  background-color: white;
  border-radius: 1.5rem;
  display: flex;
  outline: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.landscapeCutoff {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;

  .icon {
    font-size: 3rem;
  }
}

@include mobile {
  height: 5rem;
}
