@import 'src/common/_globals.scss';

.top {
  box-sizing: border-box;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 0.75rem;

  .title {
    padding: 0;
    font-size: 1.2rem;
    padding-top: 0.1rem;
  }

  .button {
    padding: 0;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 1.25rem;
    margin-left: 2.5rem;
  }
}

.middle {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  padding-top: 0.5rem;
  box-sizing: border-box;

  .textField {
    min-width: $input-width;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .note {
    max-width: $input-width * 0.95;
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: $color-gray;
    font-size: 0.9rem;
  }

  .alert {
    min-height: 3rem;
    border-radius: 1.5rem;
    width: $input-width * 0.85;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > * {
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }

    .button {
      background-color: $color-light-gray;
      color: black;
      text-transform: none;
      font-weight: 500;
      font-size: 1rem;
      border-radius: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;

      &.primary {
        background-color: $color-primary;
        color: white;
        font-weight: 700;
      }

      &.disabled {
        opacity: 0.6;
      }
    }
  }
}

@include smallScreen {
  .middle {
    .textField {
      min-width: $input-width-small-mobile;
    }
    .note {
      max-width: $input-width-small-mobile;
    }
    .alert {
      width: $input-width-small-mobile;
      display: flex;
      align-items: center;
      border-radius: 1.75rem;
    }
  }
}
