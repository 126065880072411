@import 'src/common/_globals.scss';

.introduction {
  position: relative;
  background-position: right;
  background-size: cover;
  width: 100%;
  color: white;
  overflow: hidden;

  .inner {
    padding-top: $nav-height-pc + 5rem;
    padding-bottom: 13rem;
    box-sizing: border-box;
    background: radial-gradient(
      circle at -33% 100%,
      $color-primary-darker-transparent,
      $color-primary-transparent
    );
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .scrollIcon {
      position: absolute;
      font-size: 3rem;
      opacity: 0.7;
      width: 100%;
      bottom: 3.5rem;
    }

    .sectionBorder {
      position: absolute;
      width: 100%;
      height: 5rem;
      background: white;
      border-radius: 5rem;
      bottom: -2.5rem;
    }

    .content {
      width: 55%;
      max-width: $content-max-width;

      h1 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1.1rem;
        text-align: justify;
        line-height: 1.4;
      }

      .button {
        border: 1px solid white;
        color: white;
        font-size: 1.1rem;
        text-transform: none;
        border-radius: 2rem;
        margin-top: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}

footer {
  background-color: $color-primary-footer;
  margin: 0;
  overflow: hidden;
  position: relative;
  left: 0;
  right: 0;
  padding-top: 5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 4rem;
  color: white;

  .sectionBorder {
    position: absolute;
    left: 0;
    right: 0;
    height: 5rem;
    background: white;
    border-radius: 5rem;
    top: -2.5rem;
    filter: drop-shadow(0 0.7rem 0 #00000010);
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > a {
        font-weight: 700;
        color: white;
        text-decoration: none;
        transition: opacity 0.1s ease-in-out;
        margin-right: 1rem;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5rem;

  & > section {
    width: 70%;
    max-width: $content-max-width;
    display: flex;
    flex-direction: row;
    margin-bottom: 8rem;

    &.inverted {
      flex-direction: row-reverse;
      .imageContainer {
        margin-right: -2rem;
        margin-left: 4rem;
      }
    }

    & > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      & > h1 {
        font-size: 2.2rem;
        word-wrap: break-word;
        margin: 0;
        margin-bottom: 1rem;
      }

      & > p {
        font-size: 1.1rem;
        text-align: justify;
        margin: 0;
        color: $color-gray;
        line-height: 1.6;

        &.listItem {
          margin: 0;
          margin-bottom: 0.5rem;
        }
      }
    }

    .imageContainer {
      margin-left: -2rem;
      margin-right: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15rem;
      height: 15rem;
      border-radius: 10rem;
      background-color: $color-light-gray;
      filter: drop-shadow(0 0.7rem 0 #00000010);

      & > img {
        width: 14rem;
      }
    }
  }
}

@include mobile {
  .introduction {
    .inner {
      padding-top: $nav-height-mobile + 2rem;
      padding-bottom: 8rem;

      .scrollIcon {
        font-size: 3rem;
        bottom: 2rem;
      }

      .sectionBorder {
        height: 2rem;
        border-radius: 2rem;
        bottom: -1rem;
      }

      .content {
        width: 90%;

        h1 {
          font-size: 1.7rem;
          text-align: center;
        }

        p {
          font-size: 1rem;
          line-height: 1.3;
        }
      }
    }
  }

  .mainContent {
    padding-top: 0.2rem;

    & > section {
      width: calc(100% - 2rem);
      box-sizing: border-box;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      margin-bottom: 5rem;
      &:last-child {
        margin-bottom: 0;
      }

      &.inverted {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.7rem;
        border: 2px solid $color-light-gray;
        padding: 1rem;
        padding-top: 3rem;
        padding-bottom: 3rem;

        .imageContainer {
          margin-left: 0;
          margin-right: 0;
        }
      }

      & > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        & > h1 {
          font-size: 1.5rem;
          text-align: center;
          margin-bottom: 1rem;
        }

        & > p {
          font-size: 1rem;
          text-align: justify;
          margin: 0;
          color: $color-gray;

          &.listItem {
            margin: 0;
            margin-bottom: 0.5rem;
          }
        }
      }

      .imageContainer {
        margin-left: 0;
        margin-right: 0;
        width: 10rem;
        height: 10rem;
        border-radius: 6rem;
        margin-bottom: 2.5rem;

        & > img {
          width: 10rem;
        }
      }
    }
  }

  footer {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;

    .sectionBorder {
      height: 2rem;
      border-radius: 2rem;
      top: -1rem;
    }

    .content {
      flex-direction: column;
      align-items: flex-start;

      .copyright {
        font-size: 0.9rem;
      }

      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1rem;

        & > a {
          font-weight: 700;
          color: white;
          text-decoration: none;
          transition: opacity 0.1s ease-in-out;
          margin-bottom: 0.66rem;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
