@import 'src/common/_globals.scss';

@supports (margin: unquote('max(0px)')) {
  nav {
    position: sticky;
    top: 0;
    margin-bottom: -$nav-height-pc;
    margin-right: unquote('max(#{$nav-margin}, env(safe-area-inset-right))');
    margin-left: unquote('max(#{$nav-margin}, env(safe-area-inset-left))');
    z-index: 20;
    height: $nav-height-pc;
    transition: background-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s, top ease-in-out 0.2s,
      border-radius ease-in-out 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 1.25rem 0.2rem 1.8rem;
    box-sizing: border-box;

    .logo {
      height: 62%;
      cursor: pointer;
      transition: filter ease-in-out 0.2s;
    }

    &.opaque {
      background-color: white;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 3rem;
      top: $nav-margin;

      .logo {
        filter: none;
      }
    }

    &.transparent {
      background-color: transparent;
      border-radius: 0.5rem;
      top: 0;

      .logo {
        filter: saturate(0);
        filter: brightness(10);
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;

      & > * {
        margin-left: 0.6rem;
      }
      .button {
        height: 2.5rem;
        color: white;
        border-color: rgba(255, 255, 255, 0.5);
        transition: color ease-in-out 0.2s, background-color ease-in-out 0.2s,
          border-color ease-in-out 0.2s;
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 1rem;
        border-radius: 1.25rem;
        text-transform: none;

        &.alt {
          color: black;
        }

        &.highlight {
          padding-top: 6px;
          font-weight: 700;
          border: 1px solid white;
          &.alt {
            color: white;
            background-color: $color-primary;
            border-color: transparent;
          }
        }

        &.menuButton {
          min-width: 2.5rem;
          padding: 0 0;
          &.alt {
            color: black;
            border-color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

@include mobile {
  nav {
    height: $nav-height-mobile;
    margin-bottom: -$nav-height-mobile;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;

    .logo {
      height: 60%;
    }

    &.opaque {
      border-radius: 0;
      top: 0;

      .logo {
        filter: none;
      }
    }

    .buttonContainer {
      & > * {
        margin-left: 0.3rem;
      }
    }
  }
}

@include smallScreen {
  nav {
    .buttonContainer {
      .button {
        display: none;
        &.highlight {
          display: flex;
        }
        &.menuButton {
          display: flex;
        }
      }
    }
  }
}

@include tinyScreen {
  nav {
    .buttonContainer {
      .button {
        display: none;
        &.highlight {
          display: none;
        }
        &.menuButton {
          display: flex;
        }
      }
    }
  }
}

@include largeScreen {
  nav {
    max-width: 1370px;
    margin-left: auto;
    margin-right: auto;
  }
}
