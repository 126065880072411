@import 'src/common/_globals.scss';

.accountTypeSelector {
  padding-left: 0.5rem;
  box-sizing: border-box;
  width: $input-width;
  margin-bottom: 1rem;

  & > * {
    margin-top: 0;
    margin-bottom: 0;
    height: 2.5rem;
  }
}

.contentContainer {
  width: min-content;
  padding-top: 0.5rem;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;

  .formGroupTitle {
    color: $color-gray;
    margin-left: 0.1rem;
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
  }

  .checkboxContainer {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1.5rem;

    label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      height: 3rem;
    }

    div {
      line-height: 1.25;
      margin-top: 0.5rem;
      & > a {
        text-decoration: underline;
        color: $color-primary;
        font-weight: 700;
      }
    }
  }
}

@include mobile {
  .contentContainer {
    max-height: 20rem;
  }
}

@include smallScreen {
  .accountTypeSelector {
    max-width: $input-width-small-mobile;
  }
}
