@import 'src/common/_globals.scss';

.forgottenPass {
  font-size: 0.85rem !important;
  text-transform: none !important;
  color: white !important;
  background-color: $color-error !important;
  font-weight: 700 !important;
  border-radius: 0.5rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  height: 2rem !important;
  margin-top: 0.5rem !important;
}

.registerHelp {
  color: $color-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.85rem;
  box-sizing: border-box;
  margin-bottom: 1.5rem;

  & > p {
    margin: 0;
    text-align: center;
    width: 100%;
  }

  .inlineLink {
    user-select: none;
    color: $color-primary;
    font-weight: 700;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}

@include smallScreen {
  .passwordContainer .textField {
    min-width: $input-width-small-mobile;
  }
  .registerHelp {
    & > p {
      width: $input-width-small-mobile;
    }
  }
}
