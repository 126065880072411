@import 'src/common/_globals.scss';

.email {
  font-weight: 700;
  display: block;
  background-color: $color-light-gray;
  width: fit-content;
  padding: 0.3rem 0.8rem;
  border-radius: 1rem;
  color: $color-gray;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 1.2rem;
    margin-right: 0.25rem;
    height: 100%;
  }
}
