@import 'src/common/_globals.scss';

@keyframes shadowAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.15) 50%,
      rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  padding: 20px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  animation: shadowAppear 500ms ease-in-out;

  .card {
    pointer-events: all;
    background-color: white;
    border-radius: 9px;
    padding: 18px;
    display: flex;
    align-items: center;
    max-width: 1280px;

    img {
      width: 48px;
      margin-right: 15px;
      flex-shrink: 0;
    }

    .text {
      display: flex;
      flex: 1;
      flex-direction: column;

      h2 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 1rem;
      }

      p {
        margin: 0;
        font-size: 0.875rem;
        color: $color-gray;
        line-height: 1.15rem;
      }

      a {
        font-weight: 700;
        color: $color-gray;
        text-decoration: none;

        &::before {
          content: '\1F517';
          margin-right: 3px;
        }
      }
    }

    .buttonGroup {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      column-gap: 10px;
      margin-left: 30px;
      margin-right: 5px;

      .button {
        text-transform: none;
        font-size: 1rem;
        font-weight: 600;
        background-color: $color-light-gray;
        border-radius: 50px;
        padding: 8px 20px;

        &.cta {
          background-color: $color-primary;
          color: white;
          font-weight: 700;
        }
      }
    }

    @include mobile {
      flex-direction: column;
      text-align: center;
      position: relative;
      align-items: center;
      padding-top: 25px;

      img {
        position: absolute;
        width: 60px;
        height: 60px;
        bottom: calc(100% - 35px);
        transform: rotateZ(180deg);
        right: 0;
      }

      .text {
        margin-bottom: 18px;
      }

      .buttonGroup {
        flex-direction: column-reverse;
        margin: 0;
        max-width: 350px;
        width: 100%;

        .button {
          margin: 0;
          width: 100%;

          &.cta {
            margin-bottom: 16px;
          }
        }
      }
    }

    @include tinyScreen {
      padding-top: 45px;
    }
  }
}