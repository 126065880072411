$breakpoint-mobile: 800px;
$breakpoint-small-mobile: 500px;
$breakpoint-tiny-mobile: 360px;
$breakpoint-large-screen: 1400px;

$color-light-gray: #f5f5f5;
$color-gray: #4e4e4e;
$color-error: #ff4a4a;
$color-primary: #47cdff;
$color-primary-darker: #1363b8;
$color-primary-footer: #1376b8;
$color-primary-transparent: #47cdffde;
$color-primary-darker-transparent: #1363b8de;

$border-radius-small: 0.1rem;
$border-radius-medium: 0.5rem;
$border-radius-large: 0.8rem;

$nav-margin: 1rem;
$nav-height-pc: 4rem;
$nav-height-mobile: 3.8rem;

$input-width: 22rem;
$input-width-small-mobile: 70vw;

$content-max-width: 1050px;

@mixin largeScreen() {
  @media screen and (min-width: #{$breakpoint-large-screen}) {
    @content;
  }
}

@mixin mobile() {
  @media screen and (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin smallScreen() {
  @media screen and (max-width: #{$breakpoint-small-mobile}) {
    @content;
  }
}

@mixin tinyScreen() {
  @media screen and (max-width: #{$breakpoint-tiny-mobile}) {
    @content;
  }
}
